
import request from './request';
import Axios from 'axios';
import Vue from 'vue';

export default new function API() {

  
  this.topicdetail = params => request.get({action:"topicd", ...params});
  this.topiclist = params => request.get({action:"topicl", ...params});
  this.koltopic = params => request.get({action:"koltopic", ...params});
  
  this.hometopic = params => request.get({action:"hometopic", ...params});
  //this.uploadimages = params => request.post({action:"uploadimages",contentType:'multipart/form-data', ...params});
  this.mytopic = params => request.get({action:"mytopic", ...params});
  this.save = params => request.post({action:"savetopic", ...params});
  this.removetopic = params => request.post({action:"removetopic", ...params});

  this.uploadimages = (file) => {
    let form = new FormData()
    
    form.append('files', file)
    
    const token = Vue.prototype.$util.getToken()
    let headers = {'headers': {'X-Token' : token}}

    return Axios.post(process.env.VUE_APP_DOMAIN + 'api?action=uploadimages',form, headers)
    
  }

}();
