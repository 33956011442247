<template>
  <div class=" px-0 px-sm-16 mx-sm-12" >

    <div class="section-1 mb-6  ">
      <v-carousel height=550 hide-delimiters light @change="changeBanner"  :show-arrows="!$vuetify.breakpoint.mobile" show-arrows-on-hover	>
        <template v-slot:prev="{ on, attrs }">
          <img src="../assets/arrow_left.png" class="mr-2 pointer" v-bind="attrs" v-on="on" />
        </template>
        <template v-slot:next="{ on, attrs }">
          <img src="../assets/arrow_right.png" class="ml-2 pointer" v-bind="attrs" v-on="on" />
        </template>
        <v-carousel-item
          
          v-for="(item,i) in home_banner"
          :key="i"
          class=""
        >
          <v-sheet
            
            tile
            class=""
          >
          <v-row
            class=" lighten-3 px-0 ma-0"
            :class="{grey:!$vuetify.breakpoint.mobile}"
            align="center"
            
            justify="center"
          >
            <v-col cols=12 sm=6 class="pa-0">
              <v-sheet
                  height="100%"
                  tile
                >
                <v-img style=""
                
                  :src="domain+item.adv_image" :max-height="$vuetify.breakpoint.mobile?'362':'545'"
                  @click="$router.push(item.adv_url)"
                ></v-img>
              </v-sheet>
            </v-col>
            <v-col cols=12 sm=6 class="pt-4 pt-md-14">
              <v-sheet
                  height="100%"
                  tile
                  class=" lighten-3 py-8"
                  :class="{grey:!$vuetify.breakpoint.mobile}"
                >
                <v-row
                  class="title1 text-h5 text-md-h4 font-weight-bold mb-md-6"
                  align="center"
                  justify="center"
                >
                {{item.title}}
                </v-row>
                <v-row
                  class="title2 pt-3 text-subtitle-2 text-md-subtitle-1"
                  align="center"
                  justify="center"
                >
                {{item.subtitle}}
                </v-row>
                <v-row
                  class="fill-height mt-4"
                  align="center"
                  justify="center"
                >
                
                <v-btn  dark outlined  tile class="carousel-btn mt-8 mt-md-16 " :to="item.adv_url">{{item.btntext}}</v-btn>
                </v-row>

              </v-sheet>
            </v-col>
          </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <!--<v-progress-linear :value="banner_progress" color="black" background-color="grey lighten-2" class="banner_progress" height=22></v-progress-linear>-->
      <div class="banner_progress_bar d-flex justify-center ">
        <div class="banner_progress grey lighten-2 ">
          <div class="banner_progress_current black " :style="{width:progress_width_str,left:progress_left}"></div>
          
        </div>
      </div>
    </div>

    <v-sheet fluid class="section-2 pt-4">
      <v-row :no-gutters="$vuetify.breakpoint.mobile">
        <v-col cols="6" sm="6" v-for="cate in home_cate" :key="cate.adv_image">
          <v-card  class="mx-0 pointer" flat tile>
            <v-img class="white--text align-center"
                  :src="domain+cate.adv_image" @click="$router.push(cate.adv_url)"
                >
                <div class="top-title text-h5 text-md-h3 pl-4 text-center font-weight-medium">{{cate.title}}</div>
                </v-img> 
          </v-card>
        </v-col>
        
      </v-row>
    </v-sheet>

    <v-sheet fluid class="section-3">
      <v-row :no-gutters="$vuetify.breakpoint.mobile">
        <v-col cols="12">
          <v-sheet class="my-8 mt-12 mb-4 my-md-12 pt-md-8 pb-md-1 text-center text-md-h4 home-feature-title"  tile >
            本周亮点 
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" sm="4" v-for="item in home_point" :key="item.adv_image">
          <v-img :src="domain+item.adv_image" class="white--text align-end"  @click="$router.push(item.adv_url)">
            <div class="pl-4 d-flex justify-center weekpoint-title font-weight-bold ">{{item.title}}</div>
            <div class="pl-4 mb-4 d-flex justify-center text-h6">{{item.subtitle}}</div>
          </v-img> 
        </v-col>
      </v-row>
    </v-sheet>
    <div class="text-center mt-10 mt-md-14 mb-1 home-bottom-1 text-md-h4 pt-md-6">
      时尚资讯
    </div>
    <div class="text-center home-bottom-2 mt-0 pt-1 mb-4 mb-md-10 pointer" @click="$router.push('/topic')">
      查看更多
      <v-icon  class="moreicon">mdi-chevron-right</v-icon>

    </div>

    <v-sheet fluid class="section-4 pt-4 mb-14 pb-12">
      <v-row >
        <v-col class="mt-0" cols="6" md="3" v-for="item in topics" :key="item.topic_id" style="height:350px">
          <div class="topic-item pointer" v-if="item.uid==0" @click="$router.push('/topicdetail?id='+item.topic_id)">
            <!--<div class="topic-item d-flex flex-column justify-space-between" v-if="true" >-->
              <div class="top-img">
                <v-img :src="domain+item.image" class="white--text align-end" height="325">
                  <div class="top-title topic-title pl-4 ">{{item.title}}</div>
                  <div class="top-subtitle  pl-4 mb-4">{{item.subtitle}}</div>
                </v-img>
              </div>
            
            
          </div>
          <div class="topic-item d-flex grey lighten-3 flex-column justify-space-between" v-else>
            <div class="top-img">
              <v-img :src="domain+item.image" max-height="194" :aspect-ratio="1/1"></v-img>
            </div>
            <div class="top-title topic-title px-3 py-0 pa-md-3 text-center" >{{item.title}}</div>
            <div class="top-subtitle grey--text text--darken-2 text-center pb-0">{{item.nick_name}}</div>
            <div class="top-btn text-center showdetailbtn">
              <v-btn  light plain   tile class="" @click="$router.push('/topicdetail?id='+item.topic_id)">查看详情</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>

  </div>
</template>

<script>

import topicApi from '@/api/topic'
import { mapState } from 'vuex'
export default {



  data: () => ({    //
    a: 0,
    progress_left:"0%",

    topics : []
  }),
  computed: {
    ...mapState({
      appdomain: state => state.app.domain,
      domain: state => state.app.imgdomain,
      advinfo: state => state.app.advinfo,
      topic_img :  state => state.app.topic_image,
      home_banner : state => state.app.home_banner,
      home_cate : state => state.app.home_cate,
      home_point : state => state.app.home_point,
    }),
    progress_width : function() {
      if (this.home_banner==null || this.home_banner== undefined || this.home_banner.length==0) 
      {
        return 0
      }
      return 1/this.home_banner.length*100
    },
    progress_width_str : function() {
      return this.progress_width + "%"
    }
  },
  mounted()  {
    topicApi.hometopic().then(res => {
      this.topics = res.data
    })

    this.$util.setTitle()
    this.$util.weixinShare(window.location.href, "VL.WANG时尚")
  },

  methods: {
    changeBanner(n) {
      this.progress_left = this.progress_width*n + "%"
      
    }
  }
};
</script>
<style scoped>
.content {
  width:100%;
  
}

.carousel-btn {
  background-color: black;
  color:#ffffff;
  font-weight:350;
  width:170px;
}


.home-feature-title {
  font-size: 24px;
  font-weight: bold;
}
.home-bottom-1 {
  font-size: 24px;
  font-weight: bold;
}
.home-bottom-2 {
  font-size: 14px;
  
}
.banner_progress_bar {
  height: 3px;
  position: relative;
}
.banner_progress_current {
  height: 2px;
  position:relative;
  transition:left 0.3s;
}
.banner_progress {
  width:35%;
  height: 3px;
}
.moreicon {
  margin-left:-10px;
  margin-bottom:2px;
  font-size:22px;
  font-weight: 100;
}

.weekpoint-title {
  font-size: 1.65rem !important;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0.0125em !important;
}

/deep/ .showdetailbtn span{
  border-bottom:1px solid black;
  padding-bottom:0px;
  font-size: 0.8rem;
  font-weight: 400px !important;
  opacity:1 !important;
}
/deep/ .v-window__next{
  background:none !important;
}
/deep/  .v-window__prev{
  background:none !important;
}

</style>